<template>
  <div v-show="this.inLoader" class="report-hol" style="margin-top: 50px;">
        <div  class="before scanning-block text-center py-5">
            <PulseLoader  style="display:inline-block" />
        </div>
      </div>
 <div v-show="!this.inLoader">
   <div class="form-group row mb-0">
    <div
      class="col-12 col-sm-12 col-md-5 d-flex align-items-center justify-content-start align-items-center"
    >
      <label class="mb-4 control-label"
        >{{ CookieBanner }}
        <span class="pre-tooltip-text">
          {{ AllowCookie }}
        </span>
      </label>
    </div>
    <div
      class="col-12 col-sm-12 col-md-4 d-flex align-items-center justify-content-between"
    >
      <div class="form-check mb-4 pl-0">
        <label class="toggle-box pr-0">
          <input
            class="toggle-checkbox"
            id="is_cookie_banner"
            type="checkbox"
            name="is_cookie_banner"
            :disabled="all_toggle_btn === false ? true : false || shouldBeDisabled"
            v-model="form.is_cookie_banner"
            @change="checkEnabled($event)"
          />
          <div class="toggle-switch"></div>
        </label>
      </div>
    </div>
  </div>
  <div class="form-group row mb-0">
    <div
      class="col-12 col-sm-12 col-md-5 d-flex align-items-center justify-content-start align-items-center"
    >
      <label class="mb-4 control-label"
        >{{ IncludeSubdomains }}
        <span class="pre-tooltip-text">
          {{ CoversAll }}
        </span>
      </label>
      <a
        class="mb-4"
        target="_blank"
        href="https://seerssupport.zendesk.com/hc/en-us/articles/11077406594460"
      >
        <i
          aria-hidden="true"
          class="fa fa-question-circle text-gray-dark ml-2 position-relative"
        ></i>
      </a>
    </div>
    <div
      class="col-12 col-sm-12 col-md-4 d-flex align-items-center justify-content-between"
    >
      <div class="form-check mb-4 pl-0">
        <label class="toggle-box pr-0">
          <input
            class="toggle-checkbox"
            :disabled="all_toggle_btn === false ? true : false || shouldBeDisabled"
            id="is_cookie_banner"
            type="checkbox"
            name="subdomain"
            v-model="form.subdomain"
            @change="checkPlan('subdomain', $event)"
          />
          <div class="toggle-switch"></div>
        </label>
      </div>
    </div>
  </div>
  <div class="form-group row mb-0">
    <div
      class="col-12 col-sm-12 col-md-5 d-flex align-items-center justify-content-start align-items-center"
    >
      <label class="mb-4 control-label"
        >{{ CookiePolicy }}

        <span class="pre-tooltip-text">
          {{ AddCookies }}
        </span>
      </label>
      <a
        class="mb-4"
        target="_blank"
        href="https://seerssupport.zendesk.com/hc/en-us/articles/11151102061084"
        ><i
          aria-hidden="true"
          class="fa fa-question-circle text-gray-dark ml-2 position-relative"
        >
        </i
      ></a>
    </div>
    <div
      class="col-12 col-sm-12 col-md-4 d-flex align-items-center justify-content-between"
    >
      <input
        class="form-control mb-4"
        type="text"
        placeholder="https://www.example.com/cookie-policy"
        name="cookie_policy_url"
        autocomplete="off"
        :disabled="all_toggle_btn === false ? true : false || shouldBeDisabled"
        v-model="form.cookie_policy_url"
        @keyup="updateProperties($event)"
        @keydown="updateProperties($event)"
      />
    </div>
  </div>
  <div class="form-group row mb-0">
    <div
      class="col-12 col-sm-12 col-md-5 d-flex align-items-center justify-content-start align-items-start"
    >
      <label class="mb-4 control-label"
        >{{ BlockVendors }}
        <a
          style="margin-top: -20px !important"
          class="mb-4"
          target="_blank"
          href="https://seerssupport.zendesk.com/hc/en-us/articles/11119013053084"
          ><i
            aria-hidden="true"
            class="fa fa-question-circle text-gray-dark ml-2 position-relative"
          >
          </i
        ></a>
        <span class="pre-tooltip-text">
          {{ vendorsCookies }}
        </span>
      </label>
    </div>
    <div
      class="col-12 col-sm-12 col-md-4 d-flex align-items-center justify-content-between"
    >
      <div class="form-check mb-4 pl-0">
        <label class="toggle-box pr-0">
          <input
            class="toggle-checkbox"
            id="auto_blocking"
            type="checkbox"
            name="auto_blocking"
            v-model="form.auto_blocking"
            :disabled="all_toggle_btn === false ? true : false || shouldBeDisabled"
            @change="checkPlan('auto_blocking', $event)"
          />
          <div class="toggle-switch"></div>
        </label>
      </div>
    </div>
  </div>
  <div class="form-group row mb-0" v-if="form.auto_blocking == true">
    <div
      class="col-12 col-sm-12 col-md-5 d-flex align-items-center justify-content-start align-items-center"
    >
      <label class="mb-4 control-label"
        >{{ AutoVendors }}

        <span class="pre-tooltip-text">
          {{ BlockCookies }}
        </span>
      </label>
      <a
        class="mb-4"
        target="_blank"
        href="https://seerssupport.zendesk.com/hc/en-us/articles/11119013053084"
        ><i
          aria-hidden="true"
          class="fa fa-question-circle text-gray-dark ml-2 position-relative"
        >
        </i
      ></a>
    </div>
    <div
      class="col-12 col-sm-12 col-md-4 d-flex align-items-center justify-content-between"
    >
      <div class="form-check mb-4 pl-0">
        <label class="toggle-box pr-0">
          <input
            class="toggle-checkbox"
            id="auto_block_vendor"
            type="checkbox"
            name="auto_block_vendor"
            v-model="form.auto_block_vendor"
            :disabled="all_toggle_btn === false ? true : false || shouldBeDisabled"
            @change="checkPlan('auto_block_vendor', $event)"
          />
          <div class="toggle-switch"></div>
        </label>
      </div>
    </div>
  </div>
  <div class="form-group row mb-0" v-if="form.auto_blocking == true">
    <div
      class="col-12 col-sm-12 col-md-5 d-flex align-items-center justify-content-start align-items-center"
    >
      <label class="mb-4 control-label"
        >{{ ManuallyVendors }}

        <span class="pre-tooltip-text">
          {{ BlockVendors }}
        </span>
      </label>
      <a
        class="mb-4"
        target="_blank"
        href="https://seerssupport.zendesk.com/hc/en-us/articles/11119013053084"
        ><i
          aria-hidden="true"
          class="fa fa-question-circle text-gray-dark ml-2 position-relative"
        >
        </i
      ></a>
    </div>
    <div
      class="col-12 col-sm-12 col-md-4 d-flex align-items-center justify-content-between"
    >
      <div class="form-check mb-4 pl-0">
        <label class="toggle-box pr-0">
          <input
            class="toggle-checkbox"
            type="checkbox"
            v-model="form.do_prior_consent"
            :disabled="all_toggle_btn === false ? true : false || shouldBeDisabled"
            @change="checkPlan('prior_consent', $event)"
          />
          <div class="toggle-switch"></div>
        </label>
      </div>
    </div>
  </div>
  <div class="form-group row ml-0 mb-3" style="font-size: 16px ;font-weight: 500;">FrameWorks<a class="mb-1" target="_blank" href="#">
      <i aria-hidden="true" class="fa fa-question-circle text-gray-dark ml-2 position-relative"></i>
    </a></div>
  <div class="framework" style="background: #f0f0f0 !important;">
    <hr>
  <div class="form-group row mb-0 mt-3">
    <div
      class="col-12 col-sm-12 col-md-5 d-flex align-items-center justify-content-start align-items-center"
    >
      <label class="mb-4 control-label ml-4"
        >{{ GoogleMode }}<strong>{{version}}</strong>

        <span class="pre-tooltip-text">
          {{ ActivateGcm }}
        </span>
      </label>
      <a
        class="mb-4"
        target="_blank"
        href="https://seerssupport.zendesk.com/hc/en-us/articles/11118027659292"
        ><i
          aria-hidden="true"
          class="fa fa-question-circle text-gray-dark ml-2 position-relative"
        >
        </i
      ></a>
    </div>
    <div
      class="col-12 col-sm-12 col-md-4 d-flex align-items-center justify-content-between"
    >
      <div class="form-check mb-4 pl-0">
        <label class="toggle-box pr-0">
          <input
            class="toggle-checkbox"
            id="apply_google_consent"
            type="checkbox"
            v-model="form.apply_google_consent"
            :disabled="all_toggle_btn === false ? true : false || shouldBeDisabled"
            @change="checkPlan('apply_google_consent', $event)"
          />
          <div class="toggle-switch"></div>
        </label>
      </div>
    </div>
  </div>
  

<!-- New toggle buttons -->
<div class="form-group row mb-0">
  <div class="col-12 col-sm-12 col-md-5 d-flex align-items-center justify-content-start align-items-center">
    <label class="mb-4 control-label ml-4">Global Privacy Control <b>(GPC)</b>
      <span class="pre-tooltip-text">When enabled, visitors who are using a browser or extension that is GPC compliant will have their signal automatically applied to their consent settings.</span>
    </label>
    <a class="mb-4" target="_blank" href="#">
      <i aria-hidden="true" class="fa fa-question-circle text-gray-dark ml-2 position-relative"></i>
    </a>
  </div>
  <div class="col-12 col-sm-12 col-md-4 d-flex align-items-center justify-content-between">
    <div class="form-check mb-4 pl-0">
      <label class="toggle-box pr-0">
        <input class="toggle-checkbox" id="gpc_toggle" type="checkbox" :disabled="shouldBeDisabled" v-model="gpc_toggle_section" @change="checkPlan('gpc_toggle', $event)" />
        <div class="toggle-switch"></div>
      </label>
    </div>
  </div>
</div>

<div class="form-group row mb-0">
  <div class="col-12 col-sm-12 col-md-5 d-flex align-items-center justify-content-start align-items-center">
    <label class="mb-4 control-label ml-4">Do Not Sell (CPRA)
      <span class="pre-tooltip-text">When enabled, visitors who are using a browser that sends a Do Not Sell Signal will have their signal automatically applied to their consent settings</span>
    </label>
    <a class="mb-4" target="_blank" href="#">
      <i aria-hidden="true" class="fa fa-question-circle text-gray-dark ml-2 position-relative"></i>
    </a>
  </div>
  <div class="col-12 col-sm-12 col-md-4 d-flex align-items-center justify-content-between">
    <div class="form-check mb-4 pl-0">
      <label class="toggle-box pr-0">
        <input class="toggle-checkbox" id="do_not_sale_toggle" type="checkbox" :disabled="shouldBeDisabled" v-model="do_not_sale_toggle" @change="checkPlan('do_not_sale', $event)" />
        <div class="toggle-switch"></div>
      </label>
    </div>
  </div>
</div>

<div class="form-group row mb-0">
  <div class="col-12 col-sm-12 col-md-5 d-flex align-items-center justify-content-start align-items-center">
    <label class="mb-4 control-label ml-4">Do Not Track
      <span class="pre-tooltip-text">When enabled, visitors who are using a browser that sends a Do Not Track signal will have their signal automatically applied to their consent settings.</span>
    </label>
    <a class="mb-4" target="_blank" href="#">
      <i aria-hidden="true" class="fa fa-question-circle text-gray-dark ml-2 position-relative"></i>
    </a>
  </div>
  <div class="col-12 col-sm-12 col-md-4 d-flex align-items-center justify-content-between">
    <div class="form-check mb-4 pl-0">
      <label class="toggle-box pr-0">
        <input class="toggle-checkbox" id="do_not_track_toggle" :disabled="shouldBeDisabled" type="checkbox" v-model="do_not_track_toggle" @change="checkPlan('do_not_track', $event)" />
        <div class="toggle-switch"></div>
      </label>
    </div>
  </div>
</div>

<div class="form-group row mb-0">
  <div class="col-12 col-sm-12 col-md-5 d-flex align-items-center justify-content-start align-items-center">
    <label class="mb-4 control-label ml-4">IAB TCF <B>V2</B>
      <span class="pre-tooltip-text">When enabled, Seers CMP will implement the IAB Global Privacy Platform and IAB EU TCF 2.x Frameworks along with their respective APIs.</span>
    </label>
    <a class="mb-4" target="_blank" href="#">
      <i aria-hidden="true" class="fa fa-question-circle text-gray-dark ml-2 position-relative"></i>
    </a>
  </div>
  
<!-- </div> -->

 
    <div
      class="col-12 col-sm-12 col-md-4 d-flex align-items-center justify-content-between"
    >
      <div class="form-check mb-4 pl-0">
        <label class="toggle-box pr-0">
          <input
            class="toggle-checkbox"
            id="apply_facebook_consent"
            type="checkbox"
            :disabled="all_toggle_btn === false ? true : false || shouldBeDisabled"
            v-model="this.iab_tcf_toggle"
            @change="checkPlan('iab_tcf', $event)"
          />
          <div class="toggle-switch"></div>
        </label>
      </div>
    </div>
    </div>
  
  <div class="form-group row mb-0">
    <div
      class="col-12 col-sm-12 col-md-5 d-flex align-items-center justify-content-start align-items-center"
    >
      <label class="mb-4 control-label ml-4"
        >{{ FacebookMode }}

        <span class="pre-tooltip-text">
          {{ ActivateFacebook }}
        </span>
      </label>
      <a
        class="mb-4"
        target="_blank"
        href="https://seerssupport.zendesk.com/hc/en-us/articles/11088702127644"
        ><i
          aria-hidden="true"
          class="fa fa-question-circle text-gray-dark ml-2 position-relative"
        >
        </i
      ></a>
    </div>
    <div
      class="col-12 col-sm-12 col-md-4 d-flex align-items-center justify-content-between"
    >
      <div class="form-check mb-4 pl-0">
        <label class="toggle-box pr-0">
          <input
            class="toggle-checkbox"
            id="apply_facebook_consent"
            type="checkbox"
            :disabled="all_toggle_btn === false ? true : false || shouldBeDisabled"
            v-model="form.apply_facebook_consent"
            @change="checkPlan('apply_facebook_consent', $event)"
          />
          <div class="toggle-switch"></div>
        </label>
      </div>
    </div>
  </div>
  <div class="form-group row mb-0">
    <div
      class="col-12 col-sm-12 col-md-5 d-flex align-items-center justify-content-start align-items-center"
    >
      <label class="mb-4 control-label ml-4"
        >{{ ChildPrivacy }}

        <span class="pre-tooltip-text">
          {{ ShowsConsent }}
        </span>
      </label>
      <a
        class="mb-4"
        target="_blank"
        href="https://seerssupport.zendesk.com/hc/en-us/articles/11088541440156"
        ><i
          aria-hidden="true"
          class="fa fa-question-circle text-gray-dark ml-2 position-relative"
        >
        </i
      ></a>
    </div>
    <div
      class="col-12 col-sm-12 col-md-4 d-flex align-items-center justify-content-between"
    >
      <div class="form-check mb-4 pl-0">
        <label class="toggle-box pr-0">
          <input
            class="toggle-checkbox"
            id="child_privacy"
            type="checkbox"
            :disabled="all_toggle_btn === false ? true : false || shouldBeDisabled"
            v-model="form.child_privacy"
            @change="checkPlan('child_privacy', $event)"
          />
          <div class="toggle-switch"></div>
        </label>
      </div>
    </div>
  </div>
  <div class="form-group row mb-0" v-if="form.child_privacy">
    <div
      class="col-12 col-sm-12 col-md-5 d-flex align-items-center justify-content-start align-items-center"
    >
      <label class="mb-4 control-label"
        >{{ ChildAllowded }}
        <span class="pre-tooltip-text">
          {{ AddContent }}
        </span>
      </label>
    </div>
    <div
      class="col-12 col-sm-12 col-md-4 d-flex align-items-center justify-content-between"
    >
      <input
        class="form-control mb-4"
        type="number"
        name="child_min_age"
        placeholder="Enter minimum age for allowing website content"
        :disabled="all_toggle_btn === false ? true : false || shouldBeDisabled"
        v-model="form.child_min_age"
        @change="updateChildPrivacy($event)"
      />
    </div>
  </div>
</div>
  <hr style="margin-top: 0px !important;">
  <div class="form-group row mb-0">
    <div
      class="col-12 col-sm-12 col-md-5 d-flex align-items-center justify-content-start align-items-center"
    >
      <label class="mb-4 control-label"
        >{{ ConsentLogs }}

        <span class="pre-tooltip-text">
          {{ RecordArticle }}
        </span>
      </label>
      <a
        class="mb-4"
        target="_blank"
        href="https://seerssupport.zendesk.com/hc/en-us/articles/11088228165788"
        ><i
          aria-hidden="true"
          class="fa fa-question-circle text-gray-dark ml-2 position-relative"
        >
        </i
      ></a>
    </div>
    <div
      class="col-12 col-sm-12 col-md-4 d-flex align-items-center justify-content-between"
    >
      <div class="form-check mb-4 pl-0">
        <label class="toggle-box pr-0">
          <input
            class="toggle-checkbox"
            id="is_cookie_banner"
            type="checkbox"
            v-model="form.save_consent_logs"
            :disabled="all_toggle_btn === false ? true : false || shouldBeDisabled"
            @change="checkPlan('save_consent_logs', $event)"
          />
          <div class="toggle-switch"></div>
        </label>
      </div>
    </div>
  </div>
  <div class="form-group row mb-0">
    <div
      class="col-12 col-sm-12 col-md-5 d-flex align-items-center justify-content-start align-items-center"
    >
      <label class="mb-4 control-label"
        >{{ LanguageDetection }}

        <span class="pre-tooltip-text">
          {{ SettingAutomatically }}
        </span>
      </label>
      <a
        class="mb-4"
        target="_blank"
        href="https://seerssupport.zendesk.com/hc/en-us/articles/11087984903836"
        ><i
          aria-hidden="true"
          class="fa fa-question-circle text-gray-dark ml-2 position-relative"
        >
        </i
      ></a>
    </div>
    <div
      class="col-12 col-sm-12 col-md-4 d-flex align-items-center justify-content-between"
    >
      <div class="form-check mb-4 pl-0">
        <label class="toggle-box pr-0">
          <input
            class="toggle-checkbox"
            id="is_auto_detection"
            type="checkbox"
            name="is_auto_detection"
            v-model="form.is_auto_detection"
            :disabled="all_toggle_btn === false ? true : false || shouldBeDisabled"
            @change="checkPlan('regional_detection', $event)"
          />
          <div class="toggle-switch"></div>
        </label>
      </div>
    </div>
  </div>
  <div class="form-group row mb-0" v-if="!form.is_auto_detection">
    <div
      class="col-12 col-sm-12 col-md-5 d-flex align-items-center justify-content-start align-items-center"
    >
      <label class="mb-4 control-label"
        >{{ defaultLanguageName }}
        <span class="pre-tooltip-text">
          {{ ChooseDefault }}
        </span>
      </label>
    </div>
    <div
      class="col-12 col-sm-12 col-md-4 d-flex align-items-center justify-content-start align-items-center"
    >
      <select
        class="form-control bg-white mb-4"
        v-model="form.default_language"
        name="default_language"
        :disabled="all_toggle_btn === false ? true : false || shouldBeDisabled"
        @change="setCurrentDefaultLanguage($event)"
      >
        <option
          v-for="lang in this.preDefinedLanguages"
          :value="lang.country_code"
          :key="lang.id"
        >
          {{ lang.name }}
        </option>
      </select>
    </div>
  </div>
  <div class="form-group row mb-0">
    <div
      class="col-12 col-sm-12 col-md-5 d-flex align-items-center justify-content-start align-items-center"
    >
      <label class="mb-4 control-label"
        >{{ ConsentType }}
        <span class="pre-tooltip-text">
          {{ ChooseCategories }}
        </span>
      </label>
    </div>
    <div
      class="col-12 col-sm-12 col-md-4 d-flex align-items-center justify-content-start align-items-center"
    >
      <select
        class="form-control bg-white mb-4"
        name="consent_type"
        v-model="form.consent_type"
        :disabled="all_toggle_btn === false ? true : false || shouldBeDisabled"
        @change="uncheck_specific()"
      >
        <option value="generalised">
          {{ Generalised }}
        </option>
        <option value="specific">
          {{ Specific }}
        </option>
      </select>
    </div>
  </div>
  <div class="form-group row mb-0" v-if="form.consent_type == 'specific'">
    <div
      class="col-12 col-sm-12 col-md-5 control-label d-flex justify-content-start align-items-center"
    >
      <label class="mb-4 control-label"
        >Checkboxes default mode
        <span class="pre-tooltip-text">
          Keep the cookie categories unchecked to ensure compliance.
        </span>
      </label>
      <a class="mb-4" target="_blank" href="#"
        ><i
          aria-hidden="true"
          class="fa fa-question-circle text-gray-dark ml-2 position-relative"
        ></i
      ></a>
    </div>
    <div class="col-12 col-sm-12 col-md-7 d-flex align-items-center flex-wrap">
      <div
        class="form-check d-flex align-items-center justify-content-start pl-0 mb-4 mr-3"
      >
        <input
          type="checkbox"
          name="preferences_checked"
          :disabled="shouldBeDisabled"
          v-model="form.preferences_checked"
          @change="updateConsentSpecific($event)"
          class=""
          style="width: 20px; height: 20px"
        />
        <!---->
        <label
          for="is_cookie_banner"
          class="ml-3 control-label d-flex align-items-center"
        >
          Preferences
          <i
            aria-hidden="true"
            class="fa fa-info-circle text-gray-dark ml-2 position-relative"
            ><span class="pre-tooltip-text"> Choose preferences </span></i
          >
        </label>
      </div>
      <div
        class="form-check d-flex align-items-center justify-content-start pl-0 mb-4 mr-3"
      >
        <input
          type="checkbox"
          name="statistics_checked"
          v-model="form.statistics_checked"
          :disabled="shouldBeDisabled"
          @change="updateConsentSpecific($event)"
          class=""
          style="width: 20px; height: 20px"
        />
        <!---->
        <label
          for="is_cookie_declaration_table"
          class="ml-3 control-label d-flex align-items-center"
        >
          Statistics
          <i
            aria-hidden="true"
            class="fa fa-info-circle text-gray-dark ml-2 position-relative"
            ><span class="pre-tooltip-text"> Choose Statistics </span></i
          >
        </label>
      </div>
      <div
        class="form-check d-flex align-items-center justify-content-start pl-0 mb-4 mr-3"
      >
        <input
          type="checkbox"
          name="targeting_checked"
           v-model="form.targeting_checked"
           :disabled="shouldBeDisabled"
           @change="updateConsentSpecific($event)"
          class=""
          style="width: 20px; height: 20px"
        />
        <!---->
        <label
          for="is_cookie_declaration_table"
          class="ml-3 control-label d-flex align-items-center"
        >
          Marketing/Targeting
          <i
            aria-hidden="true"
            class="fa fa-info-circle text-gray-dark ml-2 position-relative"
            ><span class="pre-tooltip-text">
              Choose Marketing/Targeting
            </span></i
          >
        </label>
      </div>
    </div>
  </div>
  <div class="form-group row mb-0">
    <div
      class="col-12 col-sm-12 col-md-5 d-flex align-items-center justify-content-start align-items-center"
    >
      <label class="mb-4 control-label"
        >{{ ConsentFrequency }}

        <span class="pre-tooltip-text">
          {{ ConsentLimit }}
        </span>
      </label>
      <a
        class="mb-4"
        target="_blank"
        href="https://seerssupport.zendesk.com/hc/en-us/articles/11087728648988"
        ><i
          aria-hidden="true"
          class="fa fa-question-circle text-gray-dark ml-2 position-relative"
        >
        </i
      ></a>
    </div>
    <div
      class="col-12 col-sm-12 col-md-4 d-flex align-items-center justify-content-between"
    >
      <select
        class="form-control bg-white"
        name="agreement_expire"
        @change="set_expiry()"
        :disabled="all_toggle_btn === false ? true : false || shouldBeDisabled"
        v-model="form.agreement_expire"
      >
        <option selected value="0">
          {{ Always }}
        </option>
        <option value="1">
          {{ Daily }}
        </option>
        <option value="7">
          {{ Weekly }}
        </option>
        <option value="30">
          {{ Monthly }}
        </option>
        <option value="90">
          {{ Quarterly }}
        </option>
        <option value="365">
          {{ Yearly }}
        </option>
      </select>
    </div>
  </div>
  <div class="row m-0 mt-4">
    <div class="col-12 pt-4 border-top px-0">
      <ul class="list-unstyled d-flex align-items-center p-0 domain-hol mb-0">
        <li class="mr-3 mb-3"><router-link to="get-code" class="btn btn-white-bg">Previous</router-link></li>
        <!-- <li class="mr-3 mb-3"><a class="btn btn-blue-bg">Next</a></li> -->
        <li class="mr-3 mb-3">
          <a class="btn btn-green-bg" :class="button_is_disabled == false ? 'disabled' : ''" @click.prevent="saveDialogueProperties()">Save Changes</a>
        </li>
      </ul>
    </div>
  </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { emitter } from "@/eventbus";
import { mapState } from 'vuex';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
export default {
  name: "CPreferences",
  components: {
    PulseLoader,
  },
  data() {
    return {
      gpc_toggle_section:false,
      do_not_track_toggle:false,
      iab_tcf_toggle:false,
      do_not_sale_toggle:false,
      defaultLanguageShow: false,
      //
      CookieBanner: "Cookie Banner",
      AllowCookie: " Allow Cookie Banner to be displayed on your website",
      IncludeSubdomains: "Include Subdomains",
      CoversAll: "Covers all subdomain under the selected domain",
      CookiePolicy: "Cookie policy & declaration url",
      AddCookies: "Add cookies policy link",
      BlockVendors: "Block vendors cookies & tracking technologies",
      vendorsCookies: "Block vendors cookies & tracking technologies",
      AutoVendors: "Auto block vendors",
      BlockCookies: "Block vendors cookies & tracking technologies",
      ManuallyVendors: "Manually block vendors",
      GoogleMode: "Google consent mode ",
      version:"V2",
      ActivateGcm:
        "When enabled, Google Consent Mode V2 for Google Analytics is supported. GCM must be implemented via your Google configuration for this option to function",
      FacebookMode: "Facebook Consent Mode",
      ActivateFacebook:
        "Activate FCM API to pause sending pixel signals to Facebook",
      ChildPrivacy: "Child privacy",
      ShowsConsent:
        "The Child Privacy mode will show layers of banners for age-appropriate child consent.",
      ChildAllowded: "Child privacy minimum age allowded",
      AddContent: "Add minimum age for allowing website content",
      ConsentLogs: "Consent Logs",
      RecordArticle: " Record users consents to comply with GDPR article 30",
      LanguageDetection: "Language auto regional detection",
      SettingAutomatically: "Setting of detecting region automatically",
      ConsentType: "Consent type",
      ChooseCategories:
        "Choose Generalise for Accept and Reject Buttons OR Specific for Cookie Categories",
      Generalised: "Generalised",
      Specific: "Specific",
      ConsentFrequency: "Consent Frequency",
      ConsentLimit: "Consent expiry limit",
      Always: "Always",
      Daily: "Daily",
      Weekly: "Weekly",
      Monthly: "Monthly",
      Quarterly: "Quarterly",
      Yearly: "Yearly",
      defaultLanguageName: "Set default language",
      ChooseDefault: 'Choose default language. Add more from "Banner Content',
    };
  },
  computed: {
    ...mapState('domain',['inLoader','user','assign_role']
    ),
    shouldBeDisabled() {
      return !this.user.isSuperAdmin && this.user.superAdminId !== null && this.assign_role === 'reader';
    },
    front_url() {
			return process.env.VUE_APP_FRONT_NEW;
		},
    button_is_disabled: function () {
      return this.$store.getters["domain/getButtonIsDisabled"];
    },
    form: function () {
      return this.$store.state.dialogue.form;
    },
    cookie_policy_url: function () {
      return this.$store.state.dialogue.form.cookie_policy_url;
    },
    consent_log_limit: function () {
      return this.$store.getters["domain/getFeatures"].consent_log_limit;
    },
    auto_blocking_feature: function () {
      return this.$store.getters["domain/getFeatures"].auto_blocking;
    },
    banner_customization: function () {
      return parseInt(
        this.$store.getters["domain/getFeatures"].banner_customization
      );
    },
    consentlog_limit_reached: function () {
      return this.$store.getters["domain/getConsentlog_limit_reached"];
    },
    subdomain: function () {
      return this.$store.getters["domain/getFeatures"].subdomain;
    },
    is_wcag_banner: function () {
      return parseInt(this.$store.getters["domain/getFeatures"].wcag);
    },
    google_consent_mode: function () {
      return parseInt(
        this.$store.getters["domain/getFeatures"].google_consent_mode
      );
    },
    facebook_consent_mode: function () {
      return parseInt(
        this.$store.getters["domain/getFeatures"].facebook_consent_mode
      );
    },
    apply_google_consent: {
      set: function (value) {
        this.$store.commit("getCode/setGoogleConsentModeButton", value);
      },
      get: function () {
        return this.$store.getters["domain/getFeatures"]
          .getGoogleConsentModeButton;
      },
    },
    apply_facebook_consent: {
      set: function (value) {
        this.$store.commit("getCode/setFacebookConsentModeButton", value);
      },
      get: function () {
        return this.$store.getters["domain/getFeatures"]
          .getFacebookConsentModeButton;
      },
    },
    is_child_privacy: function () {
      return (
        this.$store.getters["domain/getFeatures"] &&
        this.$store.getters["domain/getFeatures"].child_privacy == 1
      );
    },
    is_save_consent_logs: function () {
      // return this.features && this.features.iab == 1;
      return (
        this.$store.getters["domain/getFeatures"] &&
        this.$store.getters["domain/getFeatures"].save_consent_logs == 1
      );
    },
    defaultLanguage: function () {
      return this.$store.getters["domain/getDefaultLanguage"];
    },
    preDefinedLanguages: function () {
      return this.$store.getters["domain/getPreDefinedLanguages"];
    },
    dialogue_languages: {
      set: function (languages) {
        this.$store.commit("domain/setDialogueLanguages", languages);
      },
      get: function () {
        return this.$store.getters["domain/getDialogueLanguages"];
      },
    },
    multilingual_banner: function () {
      return parseInt(
        this.$store.getters["domain/getFeatures"].multilingual_banner
      );
    },
    allowed_languages: function () {
      switch (this.multilingual_banner) {
        case 0:
          return ["GB"];
          break;
        case 1:
          return ["GB", "US"];
          break;
        case 2:
          return ["GB", "US", "FR"];
          break;
        case 3:
          return ["GB", "US", "FR", "DE"];
          break;
        case 4:
          return ["GB", "US", "FR", "DE", "PT"];
          break;
        case 5:
          return ["GB", "US", "FR", "DE", "PT", "IT"];
          break;
        case 6:
          return ["GB", "US", "FR", "DE", "PT", "IT", "ES"];
          break;
        case 7:
          return ["GB", "US", "FR", "DE", "PT", "IT", "ES", "SA"];
          break;
        case 8:
          return [
            "US",
            "ES",
            "AL",
            "SA",
            "EN",
            "CN",
            "FR",
            "DE",
            "IT",
            "PT",
            "GB",
            "BG",
            "HR",
            "EL",
            "TR",
            "CZ",
            "DK",
            "EE",
            "FI",
            "SE",
            "HU",
            "IE",
            "LV",
            "LT",
            "MT",
            "PL",
            "RO",
            "SK",
            "SI",
            "NL",
          ];
          break;
          case 28:
          return [
            "US",
            "ES",
            "AL",
            "SA",
            "EN",
            "CN",
            "FR",
            "DE",
            "IT",
            "PT",
            "GB",
            "BG",
            "HR",
            "EL",
            "TR",
            "CZ",
            "DK",
            "EE",
            "FI",
            "SE",
            "HU",
            "IE",
            "LV",
            "LT",
            "MT",
            "PL",
            "RO",
            "SK",
            "SI",
            "NL",
          ];
          break;
        default:
          return ["GB"];
      }
    },
    cookie_declaration_table: function () {
      return parseInt(
        this.$store.getters["domain/getFeatures"].cookie_declaration_table
      );
    },
    all_toggle_btn: function () {
      return this.$store.state.domain.all_toggle_btn;
    },
  },
  mounted() {},
  methods: {
    saveDialogueProperties(){
      emitter.emit('saveDialogueProperties');
    },
    displayAlert: function () {
      this.$store.commit("domain/setShowPricingPopup", true);
    },
    checkEnabled(e) {
      if (!(this.consentlog_limit_reached <= this.consent_log_limit)) {
        Swal.fire({
          icon: "warning",
          title: "Warning",
          text: "You reached the consent limit & banner is disabled.Get more consents by Upgrading your plan.",
          showCancelButton: true,
          confirmButtonColor: "#3B6EF8",
          cancelButtonColor: "#6cc04a",
          confirmButtonText: "Upgrade Now",
          cancelButtonText: "Cancel",
        }).then(async (result) => {
          if (result.value) {
            this.form.is_cookie_banner = false;
            e.target.checked = false;
            this.$store.commit("domain/setShowPricingPopup", true);
          }
        });
        this.form.is_cookie_banner = false;

        e.target.checked = false;
      } else {
        if (!e.target.checked) {
          Swal.fire({
            title: "Are you sure?",
            text: "Are you sure you want to disable Cookie Consent Banner?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3B6EF8",
            cancelButtonColor: "#6cc04a",
            confirmButtonText: "Remove",
            cancelButtonText: "Do not Remove",
          }).then((result) => {
            if (result.value) {
              this.form.is_cookie_banner = false;
              e.target.checked = false;
              // this.$store.commit("domain/setForm",this.form);
              emitter.emit("saveDialogueProperties");
              // await this.saveDialogueProperties();
            } else {
              this.form.is_cookie_banner = true;
              e.target.checked = true;
              // emitter.emit('saveDialogueProperties');
            }
          });
        }
        if (e.target.checked) {
          // this.button_is_disabled = false;
          this.form.is_cookie_banner = true;
          // this.$store.commit("domain/setForm",this.form);
          // console.log('asa33',this.form)
          emitter.emit("saveDialogueProperties");
          // await this.saveDialogueProperties();
        }
      }
    },
    checkPlan: async function (option, event) {
      let needToUpgrade = false;
      //start subdomain
      if (option === "gpc_toggle") {
        if(event.target.checked){
          await Swal.fire({
            title: "Contact Us",
            html:
              "Contact us to enquire more about GPC settings" ,
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#3b6ef8",
            cancelButtonColor: "#6cc04a",
            confirmButtonText: "Contact Us",
            cancelButtonText: "Cancel",
          }).then((result) => {
            if (result.value) {
              this.gpc_toogle_section = false;
              window.open(`${this.front_url}/contact`, '_blank');
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              this.gpc_toogle_section = false;
            }
          });
        }
        else{
          event.target.checked = false;
          this.gpc_toogle_section = false;
        }
          event.target.checked = false;
          this.gpc_toogle_section = false;
      }
      if (option === "iab_tcf") {
        if(event.target.checked){
          await Swal.fire({
            title: "Contact Us",
            html:
              "Contact us to enquire more about IAB TCF settings" ,
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#3b6ef8",
            cancelButtonColor: "#6cc04a",
            confirmButtonText: "Contact Us",
            cancelButtonText: "Cancel",
          }).then((result) => {
            if (result.value) {
              this.iab_tcf_toggle = false;
              window.open(`${this.front_url}/contact`, '_blank');
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              this.iab_tcf_toggle = false;
            }
          });
        }
        else{
          event.target.checked = false;
          this.iab_tcf_toggle = false;
        }
          event.target.checked = false;
          this.iab_tcf_toggle = false;
      }
      if (option === "do_not_track") {
        if(event.target.checked){
          await Swal.fire({
            title: "Contact Us",
            html:
              "Contact us to enquire more about Do Not Track settings" ,
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#3b6ef8",
            cancelButtonColor: "#6cc04a",
            confirmButtonText: "Contact Us",
            cancelButtonText: "Cancel",
          }).then((result) => {
            if (result.value) {
              this.gpc_toogle_section = false;
              window.open(`${this.front_url}/contact`, '_blank');
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              this.do_not_track_toggle = false;
            }
          });
        }
        else{
          event.target.checked = false;
          this.do_not_track_toggle = false;
        }
          event.target.checked = false;
          this.do_not_track_toggle = false;
      }
      if (option === "do_not_sale") {
        if(event.target.checked){
          await Swal.fire({
            title: "Contact Us",
            html:
              "Contact us to enquire more about Do Not Sale settings" ,
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#3b6ef8",
            cancelButtonColor: "#6cc04a",
            confirmButtonText: "Contact Us",
            cancelButtonText: "Cancel",
          }).then((result) => {
            if (result.value) {
              this.do_not_sale = false;
              window.open(`${this.front_url}/contact`, '_blank');
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              this.do_not_sale_toggle= false;
            }
          });
        }
        else{
          event.target.checked = false;
          this.do_not_sale_toggle = false;
        }
          event.target.checked = false;
          this.do_not_sale_toggle = false;
      }
      if (option === "subdomain" && !this.subdomain) {
        needToUpgrade = true;
        event.target.checked = false;
        this.form.subdomain = false;
      } else if (option === "subdomain") {
        // this.button_is_disabled = false;
        // this.$store.commit("domain/setForm",this.form);
        emitter.emit("saveDialogueProperties");
        // await this.saveDialogueProperties();
      }
      //end subdomain

      //start auto_blocking
      if (option === "auto_blocking" && !this.auto_blocking_feature) {
        needToUpgrade = true;
        event.target.checked = false;
        this.form.auto_blocking = false;
      } else if (option === "auto_blocking") {
        if (event.target.checked) {
          this.auto_block_child = true;
        } else {
          this.auto_block_child = false;
        }
        // this.button_is_disabled = false;
        // await this.saveDialogueProperties();
        emitter.emit("saveDialogueProperties");
      }
      //end auto_blocking

      //strt auto_block_vendor
      if (option === "auto_block_vendor") {
        if (event.target.checked) {
          this.form.do_prior_consent = false;
          // this.show_prior_consent = true;
        } else {
          this.form.do_prior_consent = true;
        }
        // this.show_preview = false;
        // this.button_is_disabled = false;

        emitter.emit("saveDialogueProperties");
        // await this.saveDialogueProperties();
        // this.button_is_disabled = false;
        // }
      }
      //end auto_block_vendor

      if(option==="regional_detection" && this.banner_customization != 0){
        this.form.is_auto_detection = event.target.checked;
        emitter.emit("saveDialogueProperties");
      }

      //strt prior_consent
      if (this.banner_customization === 0) {
        // this.policy_enable.status = false;
        // this.policy_enable.line_through = "text-decoration: line-through";
        // this.policy_enable.disabled = true;
        if (option === "prior_consent") {
          event.target.checked = false;
          needToUpgrade = true;
          this.form.do_prior_consent = false;
        }
        // if (option === "custom_banner") {
        //   // needToUpgrade = true;
        // }

        // if(option==="regional_detection"){
        //   // needToUpgrade = true;
        //   event.target.checked = false;
        //   this.form.is_auto_detection = false;
        // }

        // if (option === "has_badge") {
        //   // needToUpgrade = true;
        //   event.target.checked = false;
        //   this.form.has_badge = false;
        // }
      } else if (
        this.banner_customization !== 0 &&
        option === "prior_consent"
      ) {
        if (event.target.checked) {
          this.form.do_prior_consent = true;
          this.form.auto_block_vendor = false;
          // this.show_preview = true;
          // this.button_is_disabled = false;
          // this.show_prior_consent = false;
          // await this.saveDialogueProperties();
          // if(this.form.auto_block_vendor = true){
          //   this.form.auto_block_vendor = false;
          // }
        } else {
          this.form.do_prior_consent = false;
          this.form.auto_block_vendor = true;
          // this.show_preview = false;
          // this.button_is_disabled = false;
          // this.show_prior_consent = true;
        }
        emitter.emit("saveDialogueProperties");
        // await this.saveDialogueProperties();
        // this.button_is_disabled = false;
      }
      // else if (this.banner_customization !== 0 && option === "apply_wcag_banner" && this.is_wcag_banner != 0)
      // {
      //   if(event.target.checked){
      //     await this.setWcagBannerproperties(true);
      //   }else{
      //     await this.setWcagBannerproperties(false);
      //   }
      //   await this.saveDialogueProperties();
      //   this.button_is_disabled = false;
      // }
      //end prior_consent
      if (option === "apply_google_consent" && event.target.checked) {
        if (!this.google_consent_mode) {
          needToUpgrade = true;
          event.target.checked = false;
          this.apply_google_consent = false;
          this.form.apply_google_consent = false;
        } else {
          needToUpgrade = false;
          await Swal.fire({
            title: "Google Consent Mode",
            html:
              "You are required to update the Google Analytics code to activate the Google Consent Mode completely. For a step-by-step guide on how to activate this feature," +
              " " +
              "<br>" +
              "click" +
              ' <a href="https://seerssupport.zendesk.com/hc/en-us/articles/11118027659292" target="_blank" style="color: #3b6ef8 !important;"> ' +
              "here" +
              "</a> ",
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#3b6ef8",
            cancelButtonColor: "#6cc04a",
            confirmButtonText: "Ok",
            cancelButtonText: "GET CODE",
          }).then((result) => {
            if (result.value) {
              this.form.apply_google_consent = true;
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              setTimeout(() => {
                  this.$router.push('/get-code');
              }, 2000);
              
              // this.$store.dispatch("loadComponent", "script");
              // this.show_preview = false;
            }
          });
          // this.button_is_disabled = false;
          // this.show_prior_consent = false;
          // this.show_preview = false;
          // await this.saveDialogueProperties();
          emitter.emit("saveDialogueProperties");
        }
      }
      // google else if
      else if (option === "apply_google_consent") {
        needToUpgrade = false;
        await Swal.fire({
          title: "Google Consent Mode",
          html:
            "You also need to remove/disable the updated changes in Google Analytics code to deactivate this feature completely. For a step-by-step guide on how to deactivate the code," +
            " " +
            "<br>" +
            "click" +
            ' <a href="https://seerssupport.zendesk.com/hc/en-us/articles/11122409786524" target="_blank" style="color: #3b6ef8 !important;"> ' +
            "here" +
            "</a> ",
          icon: "info",
          showCancelButton: true,
          confirmButtonColor: "#3b6ef8",
          cancelButtonColor: "#6cc04a",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.value) {
            this.form.apply_google_consent = false;
            // this.button_is_disabled = false;
            // this.show_prior_consent = false;
            // this.show_preview = false;
            // this.saveDialogueProperties();
            emitter.emit("saveDialogueProperties");
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            this.form.apply_google_consent = true;
            this.apply_google_consent = true;
            // this.show_preview = false;
            return false;
            // this.$store.dispatch("loadComponent", "script");
          }
        });
      }
      //end google

      if (option === "apply_facebook_consent" && event.target.checked) {
        if (!this.facebook_consent_mode) {
          needToUpgrade = true;
          event.target.checked = false;
          this.apply_facebook_consent = false;
          this.form.apply_facebook_consent = false;
        } else {
          needToUpgrade = false;
          await Swal.fire({
            title: "Facebook Consent Mode",
            html:
              "You are required to update your Facebook Pixel code to activate the Facebook Pixel Consent Mode completely. For a step-by-step guide on how to activate this feature," +
              " " +
              "<br>" +
              "click" +
              ' <a href="https://seerssupport.zendesk.com/hc/en-us/articles/11088702127644" style="color: #3b6ef8 !important;" target="_blank"> ' +
              "here" +
              "</a> ",
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#3b6ef8",
            cancelButtonColor: "#6cc04a",
            confirmButtonText: "OK",
            cancelButtonText: "GET CODE",
          }).then((result) => {
            if (result.value) {
              //
              this.form.apply_facebook_consent = true;
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              this.form.apply_facebook_consent = true;
              setTimeout(() => {
                  this.$router.push('/get-code');
              }, 2000);
              // this.$store.dispatch("loadComponent", "script");
              // this.show_preview = false;
            }
          });
          // this.button_is_disabled = false;
          // this.show_prior_consent = false;
          // this.show_preview = false;
          // await this.saveDialogueProperties();
          emitter.emit("saveDialogueProperties");
        }
      }

      // facebook else if
      else if (option === "apply_facebook_consent") {
        needToUpgrade = false;
        await Swal.fire({
          title: "Facebook Consent Mode",
          html:
            "You also need to remove/disable the updated changes in the Facebook Pixel code to deactivate this feature completely. For a step-by-step guide on how to deactivate the Facebook Pixel Consent Mode," +
            " " +
            "<br>" +
            "click" +
            ' <a href="https://seerssupport.zendesk.com/hc/en-us/articles/11101555324700" style="color: #3b6ef8 !important;" target="_blank"> ' +
            "here" +
            "</a> ",
          icon: "info",
          showCancelButton: true,
          confirmButtonColor: "#3b6ef8",
          cancelButtonColor: "#6cc04a",
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
        }).then((result) => {
          if (result.value) {
            // this.button_is_disabled = false;
            // this.show_prior_consent = false;
            // this.show_preview = false;
            // this.saveDialogueProperties();
            this.form.apply_facebook_consent = false;
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            this.apply_facebook_consent = true;
            this.form.apply_facebook_consent = true;
            // this.show_preview = false;
            return false;
          }
          emitter.emit("saveDialogueProperties");
        });
      }

      //child_privacy

      if (option === "child_privacy" && !this.is_child_privacy) {
        needToUpgrade = true;
        event.target.checked = false;
        this.form.child_privacy = false;
      } else if (option === "child_privacy") {
        // this.button_is_disabled = false;
        // this.saveDialogueProperties();
        emitter.emit("saveDialogueProperties");
      }

      //Consent Logs

      if (option == "save_consent_logs" && !this.is_save_consent_logs) {
        needToUpgrade = true;
        event.target.checked = true;
        this.form.save_consent_logs = true;
      } else if (option == "save_consent_logs") {
        // this.button_is_disabled = false;
        // this.saveDialogueProperties();
        emitter.emit("saveDialogueProperties");
      }
      if (needToUpgrade) {
          this.displayAlert();
        }
    },
    setCurrentDefaultLanguage: function (event) {
      let country_code = event ? event.target.value : "";
      if (country_code.trim().length <= 0) return;
      var lang = this.dialogue_languages.find(
        (lang) => lang.country_code === country_code
      );
      if (lang !== undefined) {
        this.$store.commit("domain/setDefaultLanguage", lang);
        this.$store.commit("domain/setCurrentLanguage", lang);
      } else {
        if (!this.allowed_languages.includes(country_code)) {
          this.form.default_language = this.allowed_languages[0];
          this.displayAlert();
          return;
        }
        lang = this.preDefinedLanguages.find(
          (lang) => lang.country_code === country_code
        );
        this.dialogue_languages.push(lang);
        this.$store.commit("domain/setCurrentLanguage", lang);
      }
      emitter.emit("saveDialogueProperties");
      // this.enableSaveButton();
      // this.saveDialogueProperties();
    },
    uncheck_specific() {
      if (this.form.consent_type == "generalised") {
        this.form.preferences_checked = false;
        this.form.statistics_checked = false;
        this.form.targeting_checked = false;
      }
      // this.button_is_disabled = false;
      emitter.emit("saveDialogueProperties");
    },
    set_expiry() {
      emitter.emit("saveDialogueProperties");
      // this.button_is_disabled = false;
      // this.saveDialogueProperties();
    },
    updateProperties: function (event) {
      let initiator = event.target.name;
      if (initiator === "cookie_policy_url") {
        if (this.cookie_declaration_table === 0) {
          event.target.value = null;
          this.displayAlert();
        }
      }
      this.updateConsentSpecific();
      // emitter.emit("saveDialogueProperties");
    },
    updateChildPrivacy(event) {
      let initiator = event.target.name;
      if (initiator == "child_min_age" && this.form.child_min_age) {
        this.$store.commit("domain/setButtonIsDisabled", true);
        // console.log(initiator);
      }
    },
    updateConsentSpecific(event){
      this.$store.commit("domain/setButtonIsDisabled", true);
    }
  },
};
</script>


