<template>
  <div v-show="this.reportLoader" class="report-hol" style="margin-top: 50px;">
        <div  class="before scanning-block text-center py-5">
            <PulseLoader  style="display:inline-block" />
        </div>
		</div>
  <div v-show="!this.reportLoader"  class="consent-log-data">
    <div class="row">
      <div class="col-md-12">
        <button
          @click="exportInCSV"     
          v-if="log_data"
          class="btn btn-primary exportcsv btn-sm float-right mb-3 ml-3"
        >
          EXPORT CSV
        </button>
        <!-- <div class="tooltip-container pl-0 mb-0" style="display: inline-block; position: relative;">
        <button class="btn btn-primary mb-2" @click="csvValueChange" style="padding: 5px 10px;"><i class="fa fa-envelope text-white" aria-hidden="true" style="font-size:20px;"></i></button>
        <span class="tooltip-text">
                This signifies that the Banner Script has not applied on website.
        </span>  
        </div> -->
        <div class="tooltip-container pl-0 mb-0" style="display: inline-block; position: relative; float: right;">
    <button class="btn btn-primary mb-2" @click="csvValueChange" style="padding: 5px 10px;">
      <i class="fa fa-envelope text-white" aria-hidden="true" style="font-size:20px;"></i>
    </button>
    <span class="tooltip-text">
      Auto Send Report
    </span>  
</div>

        <div v-if="show_export_csv == true" class="overlay">
          <Emailcsv @onHideEdit="hideEditDomain" ></Emailcsv>
</div>

      </div>
    </div>
    <div class="row">
      <div class="col-md-6 mb-4">
        <ul class="nav">
          <li class="d-flex w-100">
            <i
              class="fa fa-filter"
              style="
                right: -2px;
                float: left;
                background: rgb(230, 230, 230);
                position: relative;
                padding: 4px 3%;
                height: 37px;
                line-height: 33px;
                border-radius: 4px 0px 0px 4px;
              "
            ></i>
            <select
              name="cookies_filter"
              v-on:change="filterByCountryName($event)"
              class="form-control"
              style="
                display: block;
                width: 100%;
                height: calc(1.6em + 0.75rem + 2px);
                padding: 0.375rem 0.75rem;
                font-size: 0.9rem;
                font-weight: 400;
                line-height: 1.6;
                color: rgb(73, 80, 87);
                background: url('../..@/assets/images/design-new/cookie-consent-arrow-down.svg')
                  97% center / 2.2% no-repeat rgb(255, 255, 255) !important;
                border: 1px solid rgb(206, 212, 218);
                border-radius: 0.25rem;
                transition: border-color 0.15s ease-in-out 0s,
                  box-shadow 0.15s ease-in-out 0s;
                appearance: none;
              "
            >
              <option value="" disabled selected hidden>By Country</option>
              <option v-if="countries.length > 0" value="all">All</option>
              <option
                v-for="country in this.countries"
                :key="country.id"
                :value="country.code"
                >{{ country.name }}</option>
              <!---->
            </select>
          </li>
        </ul>
      </div>
      <div class="col-md-6 mb-4">
        <div class="d-flex w-100">
          <!-- <i
            class="fa fa-calendar"
            style="
              right: -2px;
              float: left;
              background: rgb(230, 230, 230);
              position: relative;
              padding: 4px 3%;
              height: 37px;
              line-height: 33px;
              border-radius: 4px 0px 0px 4px;
            "
          ></i> -->
          <div class="vue-daterange-picker w-100">
            <!-- <div class="form-control reportrange-text"> -->
              
              <Datepicker format="dd-MM-yyyy"  :clearable="false" v-model="date" range multi-calendars multi-calendars-solo @update:modelValue="handleDate">
              
              </Datepicker>
            <!-- </div> -->
            <!---->
          </div>
        </div>
      </div>
    </div>
    <div class="row margin-tb-30">
      <div class="col-md-4">
        <div class="report-contour">
          <p class="mb-1">All</p>
          <div class="coutour-inside">
            <div class="count-text counter-count">
              {{
                log_data
                  ? this.consentLog.total
                    ? Number(this.consentLog.total).toLocaleString("en", {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })
                    : 0
                  : 0
              }}
            </div>
            <div class="graph-img">
              <img src="@/assets//images/gharphimg.jpg" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="report-contour rc-g">
          <p class="mb-1">Accept All</p>
          <div class="coutour-inside">
            <div class="counter-count count-text c-g">
              {{
                stats.accepted
                  ? Number(stats.accepted).toLocaleString("en", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : 0
              }}
            </div>
            <div class="graph-img">
              <img src="@/assets//images/gharphimg-g.jpg" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="report-contour rc-r">
          <p class="mb-1">Reject</p>
          <div class="coutour-inside">
            <div class="counter-count count-text c-r">
              {{
                stats.rejected
                  ? Number(stats.rejected).toLocaleString("en", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : 0
              }}
            </div>
            <div class="graph-img">
              <img src="@/assets//images/gharphimg-r.jpg" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8">
        <div class="worldimg">
				<div id="kt_amcharts_3" style="height: 270px">
				</div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="world-cookie">
          <div class="world-cookie-box o">
            <p class="mb-1">Marketing</p>
            <div class="world-count w-o">
              {{
                stats.marketing
                  ? Number(stats.marketing).toLocaleString("en", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : 0
              }}
            </div>
          </div>
          <div class="world-cookie-box p">
            <p class="mb-1">Preferences</p>
            <div class="world-count w-p">
              {{
                stats.preferences
                  ? Number(stats.preferences).toLocaleString("en", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : 0
              }}
            </div>
          </div>
        </div>
        <div class="world-cookie p">
          <div class="world-cookie-box cg">
            <p class="mb-1">Statistics</p>
            <div class="world-count w-cg">
              {{
                stats.statistics
                  ? Number(stats.statistics).toLocaleString("en", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : 0
              }}
            </div>
          </div>
          <div class="world-cookie-box gray">
            <p class="mb-1">Sell my Data</p>
            <div class="world-count w-gray">
              {{
                stats.do_not_sell
                  ? Number(stats.do_not_sell).toLocaleString("en", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : 0
              }}
            </div>
          </div>
        </div>
        <div class="world-cookie"></div>
      </div>
    </div>
    <div class="row text-center">
      <div class="table-responsive">
        <table
          id="datatable-responsive"
          cellspacing="0"
          width="100%"
          class="table table-striped cookie_consent_log_table table-hover"
        >
          <thead style="border-top: 1px solid rgb(216, 216, 216)">
            <tr>
              <th class="text-blue">ID</th>
              <th class="text-blue">IP Address</th>
              <th class="text-blue">Country</th>
              <th class="text-blue">Preference</th>
              <th class="text-blue">Marketing</th>
              <th class="text-blue">Statistics</th>
              <th class="text-blue">Sell my Data</th>
              <th class="text-blue">Date and Time</th>
              <th class="text-blue">URL</th>
              <th class="text-blue">User ID</th>
              <th class="text-blue">End User Email</th>
              <!-- <th class="text-blue">Device</th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(consentItem, index) in this.consentLog.data" :key="consentItem.id">
              <td style="text-align: left">{{ start + index }}</td>
              <td style="text-align: left">
                {{ encodeIp(consentItem.ip_address) }}
              </td>
              <td style="text-align: left">{{ consentItem.country }}</td>

              <!--                        <td style="text-align: left">{{ consentItem.cookie_policy_version }}</td>-->
              <td style="text-align: center;">
                <i
                  :class="
                    consentItem.preferences == 1
                      ? 'fa fa-check fa-lg color-green'
                      : 'fa fa-times fa-lg color-red'
                  "
                  aria-hidden="true"
                />
              </td>
              <td style="text-align: center">
                <i
                  :class="
                    consentItem.marketing == 1
                      ? 'fa fa-check fa-lg color-green'
                      : 'fa fa-times fa-lg color-red'
                  "
                  aria-hidden="true"
                />
              </td>
              <td style="text-align: center">
                <i
                  :class="
                    consentItem.statistics == 1
                      ? 'fa fa-check fa-lg color-green'
                      : 'fa fa-times fa-lg color-red'
                  "
                  aria-hidden="true"
                />
              </td>
              <td style="text-align: center">
                <i
                  :class="
                    consentItem.do_not_sell == 1
                      ? 'fa fa-check fa-lg color-green'
                      : 'fa fa-times fa-lg color-red'
                  "
                  aria-hidden="true"
                />
              </td>
              <td style="text-align: left">
                {{
                  dateConsent(consentItem.date_and_time) +
                    " " +
                    time(consentItem.date_and_time)
                }}
              </td>
              <td style="text-align: left">{{ consentItem.url }}</td>
              <td style="text-align: left">{{ consentItem.user_id }}</td>
              <td style="text-align: center;">
                <span v-if="consentItem.email" style="font-size: 13px; color: black;">
                  {{ consentItem.email }}
                </span>
                <i v-else class="fa fa-times fa-lg color-red" aria-hidden="true"></i>
              </td>
              <!-- <td style="text-align: left">{{ getDeviceType(consentItem.browser) }}</td> -->
            </tr>
          </tbody>
        </table>
        

        <div class="card-footer consent-page">
        </div>
        <div class="pagination-wrapper" style="display: flex; float: inline-end; gap: 12px; margin-top: 35px;">
  
          <button 
          :disabled="!consentLog.prev_page_url" 
          @click="filterData(consentLog.current_page - 1)"
          class="btn btn-sm btn-primary" style="border-radius: 20px;"
          >
          Prev
        </button>
        <span style="font-size: 16px;">Page {{ consentLog.current_page }} of {{ consentLog.last_page }}</span>
        <button 
          :disabled="!consentLog.next_page_url" 
          @click="filterData(consentLog.current_page + 1)"
          class="btn btn-sm btn-primary"  style="border-radius: 20px;"

        >
          Next
        </button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
// // 世界地図のgeodataを取得

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { ref, onMounted } from 'vue';
import moment from "moment";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow"
import { mapState,mapMutations } from 'vuex';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import axios from 'axios';
import Emailcsv from "@/components/Emailcsv.vue";
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import Swal from "sweetalert2";

export default {
  name: "ConsentLog",
  watch:{
		domain_id: {
			// deep: true,
      // immediate:true,
			handler(){
				this.filterData();
				this.filterStats();
			}
		},
	},
  components: { 
    Emailcsv,
    Datepicker, 
    PulseLoader,
  },
   setup() {
        const date = ref();

        onMounted(() => {
          const startDate = new Date();
          const endDate = new Date();
          date.value = [startDate, endDate];
        })
        
        return {
          date,
        }
    },
  data() {
    return { 
      countryStat:[],
      consentLog: {},
      cookieTypes: [],
      stats:{},
      countries: [
    {
        "name": "Albania",
        "code": "albania"
    },
    {
        "name": "Algeria",
        "code": "algeria"
    },
    {
        "name": "Andorra",
        "code": "andorra"
    },
    {
        "name": "Angola",
        "code": "angola"
    },
    {
        "name": "Australia",
        "code": "australia"
    },
    {
        "name": "Austria",
        "code": "austria"
    },
    {
        "name": "Azerbaijan",
        "code": "azerbaijan"
    },
    {
        "name": "Bahrain",
        "code": "bahrain"
    },
    {
        "name": "Bangladesh",
        "code": "bangladesh"
    },
    {
        "name": "Barbados",
        "code": "barbados"
    },
    {
        "name": "Belarus",
        "code": "belarus"
    },
    {
        "name": "Belgium",
        "code": "belgium"
    },
    {
        "name": "Bermuda",
        "code": "bermuda"
    },
    {
        "name": "Bosnia and Herzegovina",
        "code": "bosnia-and-herzegovina"
    },
    {
        "name": "Brazil",
        "code": "brazil"
    },
    {
        "name": "Brunei Darussalam",
        "code": "brunei-darussalam"
    },
    {
        "name": "Bulgaria",
        "code": "bulgaria"
    },
    {
        "name": "Cabo Verde",
        "code": "cabo-verde"
    },
    {
        "name": "Cambodia",
        "code": "cambodia"
    },
    {
        "name": "Canada",
        "code": "canada"
    },
    {
        "name": "Cayman Islands",
        "code": "cayman-islands"
    },
    {
        "name": "Chile",
        "code": "chile"
    },
    {
        "name": "China",
        "code": "china"
    },
    {
        "name": "Croatia",
        "code": "croatia"
    },
    {
        "name": "Cyprus",
        "code": "cyprus"
    },
    {
        "name": "Czechia",
        "code": "czechia"
    },
    {
        "name": "Denmark",
        "code": "denmark"
    },
    {
        "name": "Dominican Republic",
        "code": "dominican-republic"
    },
    {
        "name": "Egypt",
        "code": "egypt"
    },
    {
        "name": "Estonia",
        "code": "estonia"
    },
    {
        "name": "Falkland Islands (Malvinas)",
        "code": "falkland-islands-malvinas"
    },
    {
        "name": "Finland",
        "code": "finland"
    },
    {
        "name": "France",
        "code": "france"
    },
    {
        "name": "Georgia",
        "code": "georgia"
    },
    {
        "name": "Germany",
        "code": "germany"
    },
    {
        "name": "Ghana",
        "code": "ghana"
    },
    {
        "name": "Greece",
        "code": "greece"
    },
    {
        "name": "Greenland",
        "code": "greenland"
    },
    {
        "name": "Guam",
        "code": "guam"
    },
    {
        "name": "Guernsey",
        "code": "guernsey"
    },
    {
        "name": "Hong Kong",
        "code": "hong-kong"
    },
    {
        "name": "Hungary",
        "code": "hungary"
    },
    {
        "name": "Iceland",
        "code": "iceland"
    },
    {
        "name": "India",
        "code": "india"
    },
    {
        "name": "Indonesia",
        "code": "indonesia"
    },
    {
        "name": "Iran (Islamic Republic of)",
        "code": "iran-islamic-republic-of"
    },
    {
        "name": "Iraq",
        "code": "iraq"
    },
    {
        "name": "Ireland",
        "code": "ireland"
    },
    {
        "name": "Isle of Man",
        "code": "isle-of-man"
    },
    {
        "name": "Israel",
        "code": "israel"
    },
    {
        "name": "Italy",
        "code": "italy"
    },
    {
        "name": "Jamaica",
        "code": "jamaica"
    },
    {
        "name": "Japan",
        "code": "japan"
    },
    {
        "name": "Jersey",
        "code": "jersey"
    },
    {
        "name": "Kazakhstan",
        "code": "kazakhstan"
    },
    {
        "name": "Kenya",
        "code": "kenya"
    },
    {
        "name": "Korea (Republic of)",
        "code": "korea-republic-of"
    },
    {
        "name": "Kuwait",
        "code": "kuwait"
    },
    {
        "name": "Latvia",
        "code": "latvia"
    },
    {
        "name": "Lebanon",
        "code": "lebanon"
    },
    {
        "name": "Lithuania",
        "code": "lithuania"
    },
    {
        "name": "Luxembourg",
        "code": "luxembourg"
    },
    {
        "name": "Macao",
        "code": "macao"
    },
    {
        "name": "Madagascar",
        "code": "madagascar"
    },
    {
        "name": "Malaysia",
        "code": "malaysia"
    },
    {
        "name": "Malta",
        "code": "malta"
    },
    {
        "name": "Martinique",
        "code": "martinique"
    },
    {
        "name": "Mauritius",
        "code": "mauritius"
    },
    {
        "name": "Mayotte",
        "code": "mayotte"
    },
    {
        "name": "Mexico",
        "code": "mexico"
    },
    {
        "name": "Moldova (Republic of)",
        "code": "moldova-republic-of"
    },
    {
        "name": "Montenegro",
        "code": "montenegro"
    },
    {
        "name": "Morocco",
        "code": "morocco"
    },
    {
        "name": "Myanmar",
        "code": "myanmar"
    },
    {
        "name": "Namibia",
        "code": "namibia"
    },
    {
        "name": "Nepal",
        "code": "nepal"
    },
    {
        "name": "Netherlands",
        "code": "netherlands"
    },
    {
        "name": "New Zealand",
        "code": "new-zealand"
    },
    {
        "name": "Nigeria",
        "code": "nigeria"
    },
    {
        "name": "North Macedonia",
        "code": "north-macedonia"
    },
    {
        "name": "Norway",
        "code": "norway"
    },
    {
        "name": "Oman",
        "code": "oman"
    },
    {
        "name": "Pakistan",
        "code": "pakistan"
    },
    {
        "name": "Palestine, State of",
        "code": "palestine-state-of"
    },
    {
        "name": "Philippines",
        "code": "philippines"
    },
    {
        "name": "Poland",
        "code": "poland"
    },
    {
        "name": "Portugal",
        "code": "portugal"
    },
    {
        "name": "Puerto Rico",
        "code": "puerto-rico"
    },
    {
        "name": "Qatar",
        "code": "qatar"
    },
    {
        "name": "Reunion",
        "code": "reunion"
    },
    {
        "name": "Romania",
        "code": "romania"
    },
    {
        "name": "Russian Federation",
        "code": "russian-federation"
    },
    {
        "name": "Rwanda",
        "code": "rwanda"
    },
    {
        "name": "Saudi Arabia",
        "code": "saudi-arabia"
    },
    {
        "name": "Senegal",
        "code": "senegal"
    },
    {
        "name": "Serbia",
        "code": "serbia"
    },
    {
        "name": "Seychelles",
        "code": "seychelles"
    },
    {
        "name": "Singapore",
        "code": "singapore"
    },
    {
        "name": "Slovakia",
        "code": "slovakia"
    },
    {
        "name": "Slovenia",
        "code": "slovenia"
    },
    {
        "name": "Somalia",
        "code": "somalia"
    },
    {
        "name": "South Africa",
        "code": "south-africa"
    },
    {
        "name": "Spain",
        "code": "spain"
    },
    {
        "name": "Sri Lanka",
        "code": "sri-lanka"
    },
    {
        "name": "Sweden",
        "code": "sweden"
    },
    {
        "name": "Switzerland",
        "code": "switzerland"
    },
    {
        "name": "Taiwan (Province of China)",
        "code": "taiwan-province-of-china"
    },
    {
        "name": "Tanzania, United Republic of",
        "code": "tanzania-united-republic-of"
    },
    {
        "name": "Thailand",
        "code": "thailand"
    },
    {
        "name": "Turkey",
        "code": "turkey"
    },
    {
        "name": "Uganda",
        "code": "uganda"
    },
    {
        "name": "Ukraine",
        "code": "ukraine"
    },
    {
        "name": "United Arab Emirates",
        "code": "united-arab-emirates"
    },
    {
        "name": "United Kingdom of Great Britain and Northern Ireland",
        "code": "united-kingdom-of-great-britain-and-northern-ireland"
    },
    {
        "name": "United States of America",
        "code": "united-states-of-america"
    },
    {
        "name": "Unknown",
        "code": "unknown"
    },
    {
        "name": "Uzbekistan",
        "code": "uzbekistan"
    },
    {
        "name": "Venezuela (Bolivarian Republic of)",
        "code": "venezuela-bolivarian-republic-of"
    },
    {
        "name": "Viet Nam",
        "code": "viet-nam"
    },
    {
        "name": "Zambia",
        "code": "zambia"
    },
    {
        "name": "Zimbabwe",
        "code": "zimbabwe"
    }
],
      start: 0,
      show_export_csv: false,
    }
  },
  computed: {
  
    cmp_url(){
			return process.env.VUE_APP_ENV_VARIABLE
		},
    userId: function () {
      return this.$store.state.domain.userId;
    },
    domain_id: function () {
      return this.$store.getters["domain/getCurrentDomainId"];
    },
    log_data: function() {
      return this.consentLog.hasOwnProperty("data")
        ? this.consentLog.data.length > 0
        : false;
    },
    country_codes: function() {
      if (!this.log_data) return [];
      return [
        ...new Set(this.consentLog.data.map((item) => item.country_code)),
      ];
    },
    ...mapState('domain',['reportLoader','user','assign_role']) ,
  },
  
  mounted() {
    // this.date = new Date();
    this.filterData();
    this.filterStats();
	
  },

  
  methods: {
    handleDate(){
      // this.date = modelData;
      // alert(myDate.toLocaleString());
      this.filterData();
      this.filterStats();

      // console.log(this.date[0])
    },
		renderChart: function() {
		am4core.useTheme(am4themes_animated);

		var chart = am4core.create("kt_amcharts_3", am4maps.MapChart);

		chart.geodata = am4geodata_worldLow;
		chart.projection = new am4maps.projections.Miller();

		var polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
		polygonSeries.mapPolygons.template.strokeWidth = 0.5;
		this.countryStat.forEach((e) => (e.fill = am4core.color("#F05C5C")));
		polygonSeries.data = this.countryStat;

		polygonSeries.useGeodata = true;
		polygonSeries.exclude = ["AQ"];
		polygonSeries.tooltip.fill = am4core.color("#000000");

		var colorSet = new am4core.ColorSet();

		var polygonTemplate = polygonSeries.mapPolygons.template;
		polygonTemplate.tooltipText = "{name}:{value}";
		polygonTemplate.togglable = true;
		polygonTemplate.propertyFields.fill = "fill";

		// Set events to apply "active" state to clicked polygons
		var currentActive;
		polygonTemplate.events.on("hit", function(event) {
			if (currentActive) {
			currentActive.setState("default");
			}
		});
		var hoverState = polygonTemplate.states.create("hover");
		hoverState.properties.fill = colorSet.getIndex(0);

		var activeState = polygonTemplate.states.create("active");
		activeState.properties.fill = colorSet.getIndex(4);

		chart.maxZoomLevel = 1;
		// chart.seriesContainer.draggable = false;
		chart.seriesContainer.resizable = false;
		},
    dateConsent(value) {
      return moment(value).format("DD/MM/YYYY ");
    },
    getDeviceType(userAgent) {
      if (userAgent.includes('iPad')) {
        return 'iPad';
      } else if (userAgent.includes('iPhone')) {
        return 'iPhone';
      } else if (userAgent.includes('Android')) {
        return 'Android';
      } else if (userAgent.includes('Windows') || userAgent.includes('Win64') || userAgent.includes('Win32')) {
        return 'Windows';
      } else if (userAgent.includes('Macintosh') || userAgent.includes('Mac OS X')) {
        return 'Macintosh';
      } else {
        return 'unknown';
      }
    },
    time(value) {
      return moment(value).format("HH:mm:ss");
    },
    filterByCountryName: function(event) {
      let country = event ? event.target.value : "";
      this.countryName = country !== "none" ? country : null;
      this.filterData();
      this.filterStats();
    },
    filterData: function(page = 1) {
  let stratDate = this.date[0] ? this.date[0] : new Date();
  let endDate = this.date[1] ? this.date[1] : new Date();
  let payload = { 
    start_date: stratDate,
    end_date: endDate,
    cookie_type: this.cookieTypes,
    country_name: this.countryName,
    domain_id: this.domain_id,
    user_id: this.userId,
  };

  this.$store.commit('domain/reportLoader', true);
  axios
    .post(`${this.cmp_url}/api/auth/get-paginated-logs?page=` + page, payload)
    .then((response) => {
      this.$store.commit('domain/reportLoader', false);
      if (response.status == 200) {
        this.consentLog = response.data.log; // Pagination data
        this.start = response.data.log.from;
        // this.countries = response.data.countries;
        // console.log("countries",this.countries);
        // this.renderChart();
      }
    })
    .catch((error) => {
      if (axios.isCancel(error)) {
        console.log('Request canceled', error.message);
      }
    });
},
    filterStats: function() {
  let stratDate = this.date[0] ? this.date[0] : new Date();
  let endDate = this.date[1] ? this.date[1] : new Date();
  let payload = { 
    start_date: stratDate,
    end_date: endDate,
    cookie_type: this.cookieTypes,
    country_name: this.countryName,
    domain_id: this.domain_id,
    user_id: this.userId,
  };

  this.$store.commit('domain/reportLoader', true);
  axios
    .post(`${this.cmp_url}/api/auth/get-log-statistics?`, payload)
    .then((response) => {
      // this.$store.commit('domain/reportLoader', false);
      if (response.status == 200) {
        this.stats = response.data.stats;
        this.countryStat = response.data.countryStats;
        this.renderChart();
      }
    })
    .catch((error) => {
      if (axios.isCancel(error)) {
        console.log('Request canceled', error.message);
      }
    });
},
    encodeIp: function(ip) {
      let new_ip = "";
      let ip_arr = ip.split(".");
      ip_arr[ip_arr.length - 1] = "xxx";
      new_ip = ip_arr.join(".");
      return new_ip;
    },
      csvValueChange: function(){
        this.show_export_csv = true;
      },
      hideEditDomain: function () {
      this.show_export_csv = false;
    },
//     exportInCSV: async function() {
//   try {
//     const user_id = this.userId;
//     const startDate = this.date[0] ? this.date[0] : new Date();
//     const endDate = this.date[1] ? this.date[1] : new Date();

//     const url = `${this.cmp_url}/api/auth/export-consent-log-to-excel`;
//     const params = new URLSearchParams({
//       start_date: startDate,
//       end_date: endDate,
//       cookie_type: this.cookieTypes,
//       country_name: this.countryName,
//       domain_id: this.domain_id,
//       user_id: user_id
//     }).toString();

//     const response = await fetch(`${url}?${params}`, {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json"
//       }
//     });

//     if (response.status === 200) {
//       Swal.fire({
//         icon: "success",
//         title: "Export Process Started",
//         text: "The consent log export process has started. You will be notified once the file is ready via email.",
//         confirmButtonText: "OK"
//       });
//     } else {
//       throw new Error("Failed to start the export process. Please try again.");
//     }
//   } catch (error) {
//     Swal.fire({
//       icon: "error",
//       title: "Error",
//       text: error.message || "An unexpected error occurred. Please try again.",
//       confirmButtonText: "OK"
//     });
//   }
// }
exportInCSV: function() {
      var user_id = this.userId;
      let stratDate = this.date[0] ? this.date[0] : new Date();
      let endDate = this.date[1] ? this.date[1] : new Date();
      window.location.href =
        `${this.cmp_url}/api/auth/export-consent-log-to-excel?start_date=` +
        stratDate +
        "&end_date=" +
        endDate +
        "&cookie_type=" +
        this.cookieTypes +
        "&country_name=" +
        this.countryName +
        "&domain_id=" +
        this.domain_id +
        "&user_id=" +
        user_id +
        "";
    },

  },
};
</script>

<style scoped>
.color-red {
  color: #fd0d1b;
  font-size: 16px;
}

.color-green {
  color: #1ec622;
  font-size: 16px;
}
.cookie_consent_log_table > thead > tr > th {
  font-size: 12px !important;
  padding: 10px;
  white-space: nowrap;
  font-weight: 600;
}
.report-contour {
  border-radius: 8px;
  padding: 10px;
  min-height: 90px;
  height: auto;
  border: 1px solid #3b6ef8;
  margin-bottom: 5px;
}
.report-contour.rc-g {
  border: 1px solid #1ec622;
}
.report-contour.rc-r {
  border: 1px solid #fd0d1b;
}
.world-cookie {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px -5px;
}
.world-cookie-box.o {
  border: 1px solid #feb12a;
}
.world-cookie-box {
  border-radius: 8px;
  padding: 10px;
  min-height: 60px;
  height: auto;
  border: 1px solid #1996fc;
  text-align: center;
  width: 100%;
  margin: 5px;
}
.world-cookie-box.p {
  border: 1px solid #9a3bf8;
}
.world-cookie-box.cg {
  border: 1px solid #33e5ca;
}
.world-cookie-box.gray[data-v-f8d04524] {
  border: 1px solid #9d9d9d;
}
.coutour-inside {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.world-count,
p {
  font-weight: bold;
}
.w-o {
  color: #feb12a;
}
.w-gray {
  color: #9d9d9d;
}
.w-cg {
  color: #33e5ca;
}
.w-p {
  color: #9a3bf8;
}
.overlay {
  opacity:1;
}
.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-text {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 130%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 10px;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
</style>
